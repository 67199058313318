import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

export const ConfigContext = React.createContext({
  config: {
    font: "itc-avant-garde-gothic-pro",
    primaryColor: "#009edb",
    primaryColorHighlight: "#00709b",
    primaryTextColor: "#FFFFFF",
    buttonColor: "#00b5ae",
    buttonColorHighlight: "#00cfc7",
    buttonTextColor: "#FFFFFF",
    logo: "https://amaze.org.au/wp-content/themes/amaze/images/ac-logo-white.png",
  },
});

const defaultContext = {
  config: {
    font: "itc-avant-garde-gothic-pro",
    primaryColor: "#009edb",
    primaryColorHighlight: "#00709b",
    primaryTextColor: "#FFFFFF",
    buttonColor: "#00b5ae",
    buttonColorHighlight: "#00cfc7",
    buttonTextColor: "#FFFFFF",
    logo: "https://amaze.org.au/wp-content/themes/amaze/images/ac-logo-white.png",
  },
};

const ConfigContextProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultContext.config);

  const updateConfig = useCallback((settings) => {
    setConfig((state) => ({
      ...state,
      ...settings,
    }));
  }, []);

  useEffect(() => {
    if (typeof ampcConfig !== "undefined") {
      // eslint-disable-next-line no-undef
      updateConfig(ampcConfig);
    }
  }, [updateConfig]);

  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

ConfigContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConfigContextProvider;
