import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import Input from "@mui/material/Input";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import { ConfigContext } from "../../context/config-context";

const TextField = ({
  label,
  value,
  error = false,
  name,
  helperText = null,
  type = "text",
  onChange,
  placeholder = "",
  required = false,
  variant = "filled",
}) => {
  const classes = getStyles();

  const { config } = useContext(ConfigContext);

  return (
    <FormControl variant={variant} className={classes.formControl}>
      <StyledInput
        config={config}
        fullWidth
        className={classes.input}
        id={name}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        error={error}
        inputProps={{
          classes: {
            root: classes.input,
            focused: classes.inputFocused,
          },
        }}
      />
      {helperText && (
        <StyledFormHelperText
          config={config}
          error={error}
          className={classes.helperText}
        >
          {helperText}
        </StyledFormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
};

TextField.defaultProps = {
  label: null,
  value: null,
  error: false,
  name: null,
  helperText: null,
  onChange: PropTypes.func,
  placeholder: null,
  required: false,
  variant: null,
  type: null,
};

const getStyles = makeStyles((theme) => ({
  helperText: {
    "&.Mui-error": {
      color: "#f06c81",
      borderLeft: "5px solid #f59aa9",
      margin: 0,
      fontSize: "14px",
      fontWeight: 300,
      letterSpacing: 0.4,
      lineHeight: "21px",
      backgroundColor: "#E6E6E5",
      padding: 5,
    },
  },
  formControl: {
    width: "100%",
    "& .MuiInput-root input": {
      "&::placeholder": {
        color: "#000",
      },
    },
  },
  inputContainer: {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
    height: "calc(2.25rem + 0.625rem)",
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5",
    color: "#212529",
    backgroundColor: "#FFF",
    backgroundClip: "padding-box",
    borderRadius: 0,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  input: {
    width: "100%",
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 400,
    color: "#212529",
    padding: 0,
    border: 0,
    boxShadow: 0,
    '& input[type="email"],& input[type="text"],& input[type="password"],& input[type="phone"],& input[type="tel"], & textarea': {
      padding: "6px 12px 11px"
    }
  },
  inputHover: {
    "&:before": {
      borderColor: "#009edb",
      borderWidth: 5,
    },
  },
  inputFocused: {
    "&:before": {
      borderColor: "#ff6900",
      borderWidth: 5,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default TextField;

const StyledFormHelperText = styled(FormHelperText)`
  font-family: ${({ config }) => config.font}, sans-serif;
`;
const StyledInput = styled(Input)`
  font-family: ${({ config }) => config.font}, sans-serif;
  background-color: #ffffff;
  border: 0;
  box-shadow: none;
  input[type="email"],
  input[type="password"],
  input[type="phone"],
  input[type="tel"],
  input[type="text"],
  textarea {
    border: 0;
    box-shadow: none;
    padding: 4px 0 5px;
    background-color: #ffffff;
    max-height: 32px;
  }
  &:before {
    border-color: ${({ config }) => config.primaryColor};
    border-width: 5px;
  }
  &:hover:not(.Mui-disabled) {
    &:before {
      border-color: ${({ config }) => config.primaryColor};
      border-width: 5px;
    }
  }
  &:after {
    border-color: #ff6900;
    border-width: 5px;
  }
  &.Mui-error:after {
    border-color: ${({ config }) => config.primaryColor};
    border-width: 5px;
  }
`;
