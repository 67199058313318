import React from "react";

import StylesAndThemes from "./styles-and-themes";
import ChatContextProvider from "../context/chat-context";
import ConfigContextProvider from "../context/config-context";

import Main from "./main";

const Root = () => {
  return (
    <>
      <StylesAndThemes>
        <ConfigContextProvider>
          <ChatContextProvider>
            <Main />
          </ChatContextProvider>
        </ConfigContextProvider>
      </StylesAndThemes>
    </>
  );
};

export default Root;
