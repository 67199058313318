/* eslint-disable no-undef */
export const launchChat = (fname, lname, email, phone, pcode) => {
  var chatButtons = document.querySelectorAll(".live-chat-link");
  Genesys("command", "Database.set", {  messaging: {    customAttributes: {      fname: fname,      lname: lname,      email: email, phone: phone, pcode: pcode,     }  }});
  Genesys("command", "Messenger.open", {},      function(o){}, function(o){Genesys("command", "Messenger.close");});
  Genesys("subscribe", "Messenger.opened", function(){
    for (var j = 0; j < chatButtons.length; j++) {
      chatButtons[j].classList.add("is-open", 'chat-active');
      chatButtons[j].removeAttribute("data-tippy-content");
    }
  });
  Genesys("subscribe", "Messenger.closed", function(){
    for (var j = 0; j < chatButtons.length; j++) {
      chatButtons[j].classList.remove("is-open", 'chat-active');
      chatButtons[j].setAttribute("data-tippy-content", "Open live chat");
    }
  });
};


export const launchChatV1 = (fname, lname, email, phone, pcode, leadSource, location, ip) => {
  // Get our buttons and container
  var chatButtons = document.getElementById("aca-launch");
  var chatContainer = document.getElementById("chat-container");

  /** Begin provided webchat code **/
  var chatConfig = {
    // Web chat application URL
    webchatAppUrl: "https://apps.mypurecloud.com.au/webchat",

    // Web chat service URL
    webchatServiceUrl: "https://realtime.mypurecloud.com.au:443",

    // Numeric organization ID
    orgId: 10032,

    // Organization name. Replace with your org name.
    orgName: "amaze",

    // Requested agent language skill (Agent must have this language skill to receive chat)
    //language: 'English - Written',

    // Requested agent skills (Agent must have these skills to receive chat)
    skills: [],
    // OR
    //skills: [],

    // Priority
    priority: 0,

    // Queue Name
    queueName: "Autism Information Webchat",

    // Log level
    // logLevel: 'DEBUG',

    // Locale code
    locale: "en",

    reconnectEnabled: true,

    reconnectOrigins: [
      "https://amaze.org.au",
      "https://www.amaze.org.au",
      "https://s3-ap-southeast-2.amazonaws.com",
    ],

    // Logo used within the chat window
    companyLogoSmall: {
      width: 249,
      height: 149,
      url: "https://amaze.org.au/wp-content/themes/amaze/images/blank.gif",
    },
    // Fallback image used for agent if no agent image is defined in the agent's PureCloud profile
    agentAvatar: {
      width: 149,
      height: 149,
      url: "https://amaze.org.au/wp-content/themes/amaze/images/agent.png",
    },

    // Text displayed with chat window is displayed
    welcomeMessage: "Recent harassment and abusive behaviour directed at our staff will be taken seriously. We may use personal information (such as your location) to prevent this.",

    // CSS class applied to the chat window
    cssClass: "webchat-frame",

    // Custom style applied to the chat window
    css: {
      width: "100%",
      height: "90%",
    },
    onlineSchedules: [
      {
        day: "Monday-Friday",
        time: "0800-1900", // Note: If this changes, also change the helper code in initialiseChat()
        city: "Australia/Sydney",
      },
    ],

    // Element that will have 'purecloud-chat-available' class added to it when
    // intersection of online and not offline schedules
    chatNowElement: ".live-chat-link",
    //cobrowseConfig: {
    //deploymentKey: "wLVq51ZtgQQ7oMXg7P7xM5GwkTt7EGvY"
    //}
  };

  // Required if reconnects are enabled
  window.PURECLOUD_WEBCHAT_FRAME_CONFIG = {
    containerEl: "chat-container",
  };

  if (
    ININ &&
    ININ.webchat &&
    ININ.webchat.create &&
    typeof ININ.webchat.create === "function"
  ) {
    ININ.webchat
      .create(chatConfig)
      .then(function (webchat) {
        // Optionally use isAutoJoined if reconnects are enabled
        if (webchat.isAutoJoined()) {
          // Disable chat button
          for (var i = 0; i < chatButtons.length; i++) {
            chatButtons[i].classList.add("is-open");
            chatButtons[i].setAttribute(
              "data-tippy-content",
              "Chat is already open"
            );
          }
          // Fix window CSS
          chatContainer.classList.add("open");
        }

        // Use getConfig.setConfigProperty() for any web chat configuration property to dynamically set config values.

        webchat.getConfig().setData({
          firstName: fname,
          lastName: lname,
          phoneNumber: phone,
          addressPostalCode: pcode,
          phoneType: "Cell",
          customField1Label: "Email",
          customField1: email,
          customField2Label: "Lead Source",
          customField2: leadSource,
          customField3Label: "Location",
          customField3: location,
          customerId: ip,
        });

        //Use the updateConfig function to update batches of properties by passing in a partially filled chatConfig object
        //webchat.updateConfig({
        //agentEmail: 'troy@ericom.com.au',
        //locale: 'en',
        //welcomeMessage: 'Welcome to support',
        //});
        webchat
          .renderFrame({
            containerEl: "chat-container",
          })
          .then(function () {
            // Add title attribute to iframe for WGAG AA
            document.getElementsByClassName("webchat-frame")[0].title =
              "Autism Information Webchat";
          });

        // Alternatively, call webchat.renderPopup here. Note that reconnects do not apply to popup chat.
        //webchat.renderPopup({
        //containerEl: 'chat-container'
        //});

        //console.log(webchat.getConfigProperty('firstName'));
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  /** End provided webchat code **/
};

export const initializeChat = () => {
  if (window.chatInitialised) {
    return;
  }

  // Get our buttons, form, and container
  var chatButtons = document.querySelectorAll(".live-chat-link");
  var preChatForm = document.getElementById("chat-form-modal");
  var chatContainer = document.getElementById("chat-container");
  var closeButton = document.querySelector(
    ".chat-container__topbar .btn-close"
  );

  // Get the time of day for showing/hiding buttons in the for loop below
  var today = new Date();
  var time = today.getHours();

  // We'll store chat state in session storage so the user can re-open it without being prompted for their details again
  // First, check if it's already been set
  var chatStarted = sessionStorage.getItem("chatStarted");
  if (chatStarted != null) {
    // convert the stored string to a boolean
    if (chatStarted === "true") {
      chatStarted = true;
    } else {
      chatStarted = false;
    }
  } else {
    // If it's null, make it false to simplify checking
    chatStarted = false;
    sessionStorage.setItem("chatStarted", chatStarted.toString());
  }

  if (chatButtons != null && chatContainer != null) {
    for (var i = 0; i < chatButtons.length; i++) {
      // Only show chat buttons at certain times of day
      // Update this if their open hours change
      // Note: 08:00 to 19:00 uses 8 and 18 because we want chat to be available at 18:55 but not 19:05, and getHours() just gives us 18 or 19
      if (time >= 8 && time <= 19) {
        chatButtons[i].classList.add("chat-available");
      } else {
      }

      // When buttons are clicked, check state and decide what to do
      // If chat has not been started, prompt for details before launching
      // If it has, then just reopen it
      // eslint-disable-next-line no-loop-func
      chatButtons[i].addEventListener("click", function () {
        // Chat not started in current session
        if (!chatStarted) {
          // Open the form modal
          $(preChatForm).modal("show");

          // When pre-chat form is validated and submitted, open the chat container
          preChatForm.addEventListener("submit", function (event) {
            event.preventDefault();

            // Set up data for the chat script
            var fname = document.getElementById("chatFirstName").value;
            var lname = document.getElementById("chatLastName").value;
            var email = document.getElementById("chatEmail").value;
            var pcode = document.getElementById("chatPostcode").value;
            var phone = document.getElementById("chatPhone").value;
            var leadSource = document.getElementById("leadSource").value;
            var location = document.getElementById("location").value;
            var ip = document.getElementById("ip").value;

            // Validate and proceed if provided details are valid
            if (validatePreChatForm()) {
              // Add class to open chat container
              chatContainer.classList.add("open");

              // Close the modal
              $(preChatForm).modal("hide");

              // Update state in session storage
              chatStarted = true;
              sessionStorage.setItem("chatStarted", chatStarted.toString());

              // Launch chat script, passing it the values from our form
              launchChat(fname, lname, email, phone, pcode, leadSource, location, ip);
              // launchChat(fname, lname, email, pcode);

              // Loop through the buttons and adjust their styling and tooltip
              for (var j = 0; j < chatButtons.length; j++) {
                chatButtons[j].classList.add("is-open");
                chatButtons[j].setAttribute(
                  "data-tippy-content",
                  "Chat is already open"
                );
              }
            }
          });
        } else {
          launchChat();

          // Add class to open chat container
          chatContainer.classList.add("open");

          // Loop through the buttons and adjust their styling and tooltip
          for (var j = 0; j < chatButtons.length; j++) {
            chatButtons[j].classList.add("is-open");
            chatButtons[j].setAttribute(
              "data-tippy-content",
              "Chat is already open"
            );
          }
        }
      });
      if (i === chatButtons.length - 1) {
        window.chatInitialised = true;
      }
    }
  }

  // Close the chat container when our close button is clicked, and set buttons back to their default state
  if (closeButton != null && chatContainer != null) {
    closeButton.addEventListener("click", function () {
      // Loop through the buttons and adjust them all at once
      for (var j = 0; j < chatButtons.length; j++) {
        chatButtons[j].classList.remove("is-open");
        chatButtons[j].setAttribute("data-tippy-content", "Open live chat");
      }

      // Remove class from chat container to close it
      chatContainer.classList.remove("open");
    });
  }
};
