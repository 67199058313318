import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { launchChat as launchPurecloudChat } from "../utils/chat";

import { useLocalStorage } from "../hooks/use-local-storage";

export const ChatContext = React.createContext({
  formValues: {
    chatFirstName: "",
    chatLastName: "",
    chatEmail: "",
    chatPostcode: "",
    leadSource: "",
    chatPhone: "",
  },
  chatActive: false,
  formActive: false,
});

const defaultContext = {
  formValues: {
    chatFirstName: "",
    chatLastName: "",
    chatEmail: "",
    chatPostcode: "",
    leadSource: "",
    chatPhone: "",
  },
  chatActive: false,
  formActive: false,
};

const ChatContextProvider = ({ children }) => {
  // const [values, setValues] = useState(defaultContext.formValues);
  const [values, setValues] = useLocalStorage(
    "acChatFormContact",
    defaultContext.formValues
  );

  const [launchRequested, setLaunchRequested] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [formActive, setFormActive] = useState(false);

  const chatStarted = Boolean(sessionStorage.getItem("chatStarted") === "true");

  const launchChat = useCallback(() => {
    setFormActive(false);
    if (
      values.chatFirstName !== "" &&
      values.chatLastName !== "" &&
      values.chatEmail !== "" &&
      values.chatPostcode !== ""
    ) {
      setChatActive(true);
      launchPurecloudChat(
        values.chatFirstName,
        values.chatLastName,
        values.chatEmail,
        values.chatPhone,
        values.chatPostcode,
        values.leadSource,
        values.location,
        values.ip,
      );
    }
  }, [values]);

  const updateFormValues = useCallback(
    (values) => {
      setValues((state) => ({
        ...state,
        ...values,
      }));
    },
    [setValues]
  );

  useEffect(() => {
    if (
      values.chatFirstName !== "" &&
      values.chatLastName !== "" &&
      values.chatEmail !== "" &&
      values.chatPostcode !== "" &&
      launchRequested
    ) {
      setLaunchRequested(false);
      launchChat();
    }
  }, [launchChat, launchRequested, values]);


  return (
    <ChatContext.Provider
      value={{
        values,
        setValues,
        launchRequested,
        setLaunchRequested,
        updateFormValues,
        launchChat,
        chatActive,
        setChatActive,
        chatStarted,
        formActive,
        setFormActive,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChatContextProvider;
