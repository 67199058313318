import React, { useContext, useEffect, useState, useCallback } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Link from "@mui/material/Link";
import axios from 'axios'

import { ChatContext } from "../context/chat-context";
import { ConfigContext } from "../context/config-context";

import TextField from "./common/text-field";

const validationSchema = yup.object({
  chatEmail: yup
    .string("Please enter your email address")
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  chatFirstName: yup
    .string("Please enter your first name")
    .required("Please enter your first name"),
  chatLastName: yup
    .string("Please enter your last name")
    .required("Please enter your last name"),
  chatPostcode: yup
    .string("Please enter your postcode")
    .required("Please enter your postcode"),
});

const Form = () => {
  const { updateFormValues, launchChat, setLaunchRequested } =
    useContext(ChatContext);
  const { config } = useContext(ConfigContext);
  const [locationChecked, setLocationChecked] = useState(false);
  const [locationData, setLocationData] = useState({ location: 'Unknown', ip: 'Unknown'});

  //creating function to load ip address from the API
  const getIp = useCallback(async () => {
    const res = await axios.get('https://geolocation-db.com/json/');

    let locationString = 'Unkown';
    if(res.data.state) {
      locationString = res.data.state;
    } 
    
    if(locationString || res.data?.IPv4) {
      setLocationData({
        location: locationString,
        ip: res.data?.IPv4,
      })
    }
    
  }, []);

  const formik = useFormik({
    initialValues: {
      chatFirstName: "",
      chatLastName: "",
      chatEmail: "",
      chatPostcode: "",
      leadSource: "",
      chatPhone: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLaunchRequested(true);
      let leadSourceString = values.leadSource;
      // eslint-disable-next-line no-restricted-globals
      if (location && location.hostname) {
        if(leadSourceString !== '') {
          leadSourceString += ' - ';
        }
        // eslint-disable-next-line no-restricted-globals
        leadSourceString += location.hostname;
      }
      const valsToSend = { ...values, leadSource: leadSourceString, location: locationData.location, ip: locationData.ip};
      updateFormValues(valsToSend).then(launchChat());
      sessionStorage.setItem("chatStarted", "true");
    },
  });

  useEffect( () => {
    
    if(!locationChecked) {
      getIp();
      setLocationChecked(true);
    }
    
 }, [getIp, locationChecked])


  return (
    <Container>
      <FormContainer>
        <ChatForm>
          <form onSubmit={formik.handleSubmit}>
            {/* <input type="hidden" name="location" id="location" value={location ? location : ''} />
            <input type="hidden" name="ip" id="ip" value={ip ? ip : ''} /> */}
            <div style={{ display: 'none' }}>
              <TextField
                label={null}
                name="location"
                id="location"
                type="hidden"
                onChange={formik.handleChange}
                value={formik.values.location || 'Unknown'}
              />
              <TextField
                label={null}
                name="ip"
                id="ip"
                type="hidden"
                onChange={formik.handleChange}
                value={formik.values.ip || 'Unknown'}
              />
            </div>
            <FormRow>
              <FormCol col={0.5}>
                <TextField
                  placeholder="Your first name *"
                  label={null}
                  variant="outlined"
                  name="chatFirstName"
                  type="text"
                  required
                  value={formik.values.chatFirstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chatFirstName &&
                    Boolean(formik.errors.chatFirstName)
                  }
                  helperText={
                    formik.touched.chatFirstName && formik.errors.chatFirstName
                  }
                />
              </FormCol>
              <FormCol col={0.5}>
                <TextField
                  placeholder="Your last name *"
                  label={null}
                  variant="outlined"
                  name="chatLastName"
                  type="text"
                  required
                  value={formik.values.chatLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chatLastName &&
                    Boolean(formik.errors.chatLastName)
                  }
                  helperText={
                    formik.touched.chatLastName && formik.errors.chatLastName
                  }
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol col={1}>
                <TextField
                  placeholder="Your email address *"
                  label={null}
                  variant="outlined"
                  name="chatEmail"
                  type="email"
                  required
                  value={formik.values.chatEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chatEmail && Boolean(formik.errors.chatEmail)
                  }
                  helperText={
                    formik.touched.chatEmail && formik.errors.chatEmail
                  }
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol col={1}>
                <TextField
                  placeholder="Your postcode *"
                  label={null}
                  variant="outlined"
                  name="chatPostcode"
                  type="text"
                  required
                  value={formik.values.chatPostcode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chatPostcode &&
                    Boolean(formik.errors.chatPostcode)
                  }
                  helperText={
                    formik.touched.chatPostcode && formik.errors.chatPostcode
                  }
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol col={1}>
                <TextField
                  placeholder="How did you hear about us?"
                  label={null}
                  variant="outlined"
                  name="leadSource"
                  type="text"
                  required
                  value={formik.values.leadSource}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.leadSource &&
                    Boolean(formik.errors.leadSource)
                  }
                  helperText={
                    formik.touched.leadSource && formik.errors.leadSource
                  }
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol col={1}>
                <TextField
                  placeholder="You phone number"
                  label={null}
                  variant="outlined"
                  name="chatPhone"
                  type="text"
                  required
                  value={formik.values.chatPhone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.chatPhone && Boolean(formik.errors.chatPhone)
                  }
                  helperText={
                    formik.touched.chatPhone && formik.errors.chatPhone
                  }
                />
              </FormCol>
            </FormRow>
            <SubmitRow>
              <Terms font={config.font}>
                This information is confidential and stored securely by Amaze,
                read more on our{" "}
                <TermsLink
                  target="_blank"
                  href="https://www.amaze.org.au/about-amaze/privacy-policy/"
                  rel="noreferrer"
                >
                  Privacy Policy
                </TermsLink>
              </Terms>
              <SubmitContainer>
                <SubmitButton config={config} onClick={formik.handleSubmit}>
                  Start Chat
                </SubmitButton>
              </SubmitContainer>
            </SubmitRow>
          </form>
        </ChatForm>
      </FormContainer>
      <Bottom>&nbsp;</Bottom>
    </Container>
  );
};

export default Form;

const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  padding: 15px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
`;
const Terms = styled.div`
  font-size: 12.85px;
  letter-spacing: 0.4px;
  font-family: ${({ font }) => font}, sans-serif;
  color: #212529;
  font-weight: 300;
  flex-basis: 0;
  flex-grow: 1;
`;
const SubmitContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  text-align: right;
`;

const TermsLink = styled(Link)`
  color: #000;
  text-decoration-color: #000;
`;

const FormRow = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const FormCol = styled.div`
  padding: 7.5px 15px;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    width: ${(props) => Number(props.col) * 100}%;
  }
`;

const SubmitButton = styled.button`
  position: relative;
  display: inline-block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;

  font-family: ${({ config }) => config.font}, sans-serif;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ config }) => config.primaryTextColor};
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0;
  border-bottom: 5px solid rgba(0, 0, 0, 0.05);
  background-color: ${({ config }) => config.primaryColor};
  padding: 15px 30px 10px;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
  &:hover,
  &:focus {
    background-color: #e6e6e5;
    border-color: ${({ config }) => config.primaryColor};
    color: ${({ config }) => config.primaryColor};
  }

  &:active {
    color: #fff;
    background-color: ${({ config }) => config.primaryColorHighlight};
    border-color: ${({ config }) => config.primaryColorHighlight};
    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(38 173 224 / 50%);
    }
  }
`;

const Container = styled.div`
  // padding: 16px 0;
`;

const Bottom = styled.div`
  text-align: right;
  justify-self: flex-end;
`;

const FormContainer = styled.div``;

const ChatForm = styled.div``;
