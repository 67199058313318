import React from "react";
import PropTypes from "prop-types";
// import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";

const StylesAndThemes = ({ children }) => {
  const theme = React.useMemo(() => {
    return createMuiTheme({
      breakpoints: {
        // xl: 1440
      },
      typography: {
        fontFamily: [
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        body1: {
          lineHeight: "1.6em",
          fontWeight: "400",
          fontFamily: [
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
        overline: {
          textTransform: "none",
          fontSize: 14,
        },
      },
      palette: {
        primary: {
          main: "#009edb",
          contrastText: "#FFFFFF",
        },
        secondary: {
          main: "#177AD0",
          contrastText: "#FFFFFF",
        },
        background: {
          default: "#F7F7F7",
        },
      },
      overrides: {
        MuiLink: {
          root: {
            cursor: "pointer",
          },
        },
      },
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {children}
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

StylesAndThemes.propTypes = {
  children: PropTypes.element,
};

StylesAndThemes.defaultProps = {
  children: null,
};

export default React.memo(StylesAndThemes);

const GlobalStyle = createGlobalStyle`
  #aca-chat {
    color: #212529;
    letter-spacing: .5px
  }
`;
