import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";

import { Hex, Chat, Close, CloseWhite } from "./common/icons";

import { ChatContext } from "../context/chat-context";
import { ConfigContext } from "../context/config-context";

import Form from "./form";

const Main = () => {
  const {
    chatStarted,
    chatActive,
    setChatActive,
    formActive,
    setFormActive,
    values,
    launchRequested,
    setLaunchRequested,
    launchChat,
  } = useContext(ChatContext);
  const { config } = useContext(ConfigContext);
  const [chatHidden, setChatHidden] = useState(false);

  const chatClick = useCallback(() => {
    if(chatHidden) {
      setChatHidden(false);
    } else if (!chatStarted) {
      if (
        values.chatFirstName !== "" &&
        values.chatLastName !== "" &&
        values.chatEmail !== "" &&
        values.chatPostcode !== "" &&
        !launchRequested
      ) {
        setLaunchRequested(true);
        // launchChat();
      } else {
        setChatActive(false);
        setFormActive(true);
        setLaunchRequested(false);
      }
    } else {
      setLaunchRequested(true);
      setChatActive(true);
      launchChat();
    }
  }, [
    chatHidden,
    chatStarted,
    launchChat,
    launchRequested,
    setChatHidden,
    setChatActive,
    setFormActive,
    setLaunchRequested,
    values.chatEmail,
    values.chatFirstName,
    values.chatLastName,
    values.chatPostcode,
  ]);

  return (
    <MainContainer>
      {!chatStarted && (
        <>
          {formActive && (
            <FormPopup>
              <FormPopupBG />
              <FormPopupContainer>
                <FormHeader>
                  <FormClose
                    color={config.primaryColor}
                    onClick={(e) => {
                      e.preventDefault();
                      setFormActive(false);
                    }}
                  />
                  <FormTitle font={config.font}>
                    Start your chat with an Autism Connect advisor
                  </FormTitle>
                  <FormSubTitle font={config.font}>
                    Your name, email and postcode are required
                  </FormSubTitle>
                </FormHeader>
                <Form />
              </FormPopupContainer>
            </FormPopup>
          )}
        </>
      )}
      <ChatButton
        id="aca-launch"
        config={config}
        // className={(!chatActive || chatHidden) ? 'live-chat-link' : 'live-chat-link chat-active'}
        className={'live-chat-link'}
        onClick={(e) => {
          e.preventDefault();
          chatClick();
        }}
      >
        <ChatButtonBG className="button-bg" config={config} />
        <ChatButtonIcon color={config.buttonTextColor} />
        <ChatButtonText config={config}>Live chat</ChatButtonText>
      </ChatButton>
      
    </MainContainer>
  );
};

export default Main;

const FormPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
`;

const FormPopupBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
`;

const FormPopupContainer = styled.div`
  margin: 20px auto;
  background-color: #ffffff;
  position: relative;
  z-index: 301;
  border-radius: 4.8px;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
`;

const FormHeader = styled.div`
  padding: 16px;
`;

const FormClose = styled(Close)`
  width: 26px;
  height: 26px;
  float: right;
  margin-left: 30px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  path {
    stroke: ${({ color }) => color};
  }
`;

const FormTitle = styled.div`
  font-size: 24px;
  line-height: 28.8px;
  max-width: 310px;
  font-family: ${({ font }) => font}, sans-serif;
  color: #212529;
  font-weight: 700;
`;

const FormSubTitle = styled.div`
  padding-top: 20px;
  font-size: 12.85px;
  letter-spacing: 0.4px;
  font-family: ${({ font }) => font}, sans-serif;
  color: #212529;
  font-weight: 300;
`;

const ChatButton = styled.div`
  transition: all linear 300ms;
  position: fixed;
  bottom: 30px;
  right: 24px;
  z-index: 20;
  width: 102px;
  height: 100px;
  cursor: pointer;
  .button-bg {
    path {
      transition: all linear 300ms;
      fill: ${({ config }) => config.buttonColor};
    }
  }
  &:hover {
    .button-bg {
      path {
        fill: ${({ config }) => config.buttonColorHighlight};
      }
    }
  }
  &.chat-active {
    z-index: -10;
    opacity: 0;
  }
`;

const ChatButtonBG = styled(Hex)`
  position: absolute;
  top: -1px;
  left: 1px;
  width: 100%;
  height: 100%;
  z-index: 21;
`;

const ChatButtonText = styled.div`
  position: relative;
  z-index: 22;
  text-align: center;
  color: ${({ config }) => config.buttonTextColor};
  font-family: ${({ config }) => config.font}, sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  max-width: 60px;
  margin 0 auto;
`;

const ChatButtonIcon = styled(Chat)`
  width: 34px;
  height: 27px;
  margin: 15px auto 2px;
  position: relative;
  z-index: 23;
  display: block;
  path {
    fill: ${({ color }) => color};
  }
`;

const MainContainer = styled.div``;
